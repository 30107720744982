import Alert from "bootstrap/js/dist/alert";

export type AlertType = "primary" | "success" | "danger" | "warning";

const alertTypeToClass = {
	primary: "alert-primary",
	success: "alert-success",
	danger: "alert-danger",
	warning: "alert-warning",
};

export class AlertBuilder {
	private readonly element: HTMLDivElement;

	constructor(type: AlertType) {
		this.element = document.createElement("div");
		this.element.classList.add("alert", alertTypeToClass[type], "show");
		this.element.setAttribute("role", "alert");
	}

	spinner(): AlertBuilder {
		const spinner = document.createElement("div");
		spinner.classList.add("spinner-border", "spinner-border-sm", "me-2");
		spinner.setAttribute("role", "status");
		this.element.appendChild(spinner);
		return this;
	}

	text(text: string): AlertBuilder {
		this.element.appendChild(document.createTextNode(text));
		return this;
	}

	add(element: Node): AlertBuilder {
		this.element.appendChild(element);
		return this;
	}

	build(): HTMLDivElement {
		return this.element;
	}

	dismissible(): DismissibleAlertBuilder {
		return new DismissibleAlertBuilder(this.element);
	}
}

export class DismissibleAlertBuilder {
	private readonly element: HTMLDivElement;
	private readonly dismiss: HTMLButtonElement;
	private readonly alert: Alert;

	constructor(element: HTMLDivElement) {
		this.element = element;

		this.dismiss = document.createElement("button");
		this.dismiss.classList.add("btn-close", "no-focus-shadow");
		this.dismiss.setAttribute("type", "button");
		this.dismiss.setAttribute("data-bs-dismiss", "alert");
		this.dismiss.setAttribute("aria-label", "Close");

		this.element.appendChild(this.dismiss);
		this.element.classList.add("alert-dismissible", "fade");

		this.alert = new Alert(this.element);
	}

	hideAfter(duration: number): DismissibleAlertBuilder {
		const { alert, dismiss, element } = this;
		const timeout = setTimeout(() => alert.close(), duration);
		dismiss.addEventListener("click", e => {
			e.stopPropagation();
			clearTimeout(timeout);
		});
		element.addEventListener("click", e => {
			if (e.target === dismiss) {
				return;
			}
			element.classList.remove("fade");
			alert.close();
			clearTimeout(timeout);
		});
		return this;
	}

	build(): HTMLDivElement {
		return this.element;
	}

	getAlert(): Alert {
		return this.alert;
	}
}
