export function getElementById(id: string): HTMLElement {
	const element = document.getElementById(id);
	if (element == null) {
		throw new Error('Could not find element with id "' + id + '"');
	}
	return element as HTMLElement;
}

export function emptyElement(element: HTMLElement): HTMLElement {
	element.textContent = "";
	return element;
}

export function setChild(element: HTMLElement, child: Node): HTMLElement {
	emptyElement(element);
	element.appendChild(child);
	return element;
}
